export default (axios, alert) => ({
  status () {
    return axios.get('/customer/status').then(({ data }) => data)
  },

  oldLogin () {
    return axios.get('/customer/doppelt').then(({ data }) => data)
  },

  aufstellung () {
    return axios.get('/customer/aufstellung').then(({ data }) => data?.finanzAufstellung?.aufstellung || [])
  },

  requestedDocuments () {
    return axios.get('/customer/document/requested').then(({ data }) => data || [])
  },

  updateAufstellung (aufstellung) {
    return axios.post('/customer/aufstellung', aufstellung)
  },

  deleteAufstellung () {
    return axios.delete('/customer/aufstellung')
  },

  updateIban (iban) {
    return axios.post('/customer/update_iban', { iban })
  },

  updateKreditsumme (kreditsumme) {
    return axios.put('/customer/update_kreditsumme', { kreditsumme })
  },

  updateMitantragssteller (mitantragssteller) {
    return axios.post('/customer/mitantragsteller', mitantragssteller)
  },

  sendGrundbuch (grundbuch) {
    // remove the data:image/png;base64, part
    grundbuch = grundbuch.replace(/^data:image\/png;base64,/, '')
    return axios.post('/customer/document/grundbuch', {
      content: grundbuch
    })
  },

  sendGrundbuchV2 (request) {
    // remove the data:image/png;base64, part
    request.signature = request.signature.replace(/^data:image\/png;base64,/, '')
    return axios.post('/customer/v2/document/grundbuch', request)
  },

  sendImmodaten (immodaten) {
    return axios.post('/customer/save_immo_daten', immodaten)
  },

  sendBaubeschreibung (baubeschreibung) {
    // remove the data:image/png;base64, part
    baubeschreibung.unterschrift_bb = baubeschreibung.unterschrift_bb.replace(/^data:image\/png;base64,/, '')
    return axios.post('/customer/baubeschreibung', baubeschreibung)
  },

  sendBaubeschreibungV2 (baubeschreibung) {
    // remove the data:image/png;base64, part
    baubeschreibung.unterschrift_bb = baubeschreibung.unterschrift_bb.replace(/^data:image\/png;base64,/, '')
    return axios.post('/customer/v2/baubeschreibung', baubeschreibung)
  },

  deleteFile (udid) {
    return axios.delete(`/customer/document/${udid}`)
  },

  sendMessage (message) {
    return axios.post('/customer/info', {
      info: message
    })
  },

  sendGoldGeld (goldgeld) {
    return axios.post('/customer/goldgeld', goldgeld)
  },

  sendMaklerauftrag () {
    return axios.post('/customer/maklerauftrag')
  },

  sendExistenzschutz (form) {
    return axios.post('/customer/existenzschutz', form)
  },

  sendFinanzSchutzbrief (form) {
    // return axios.post('/customer/finanzschutzbrief', form)
    return axios.post('/customer/wuerzburger', form)
  },

  verifyBirthday (form) {
    return axios.post('/customer/verify_birthday', form)
  },

  sendSEPASign (sign, iban = null) {
    // remove the data:image/png;base64, part
    sign = sign.replace(/^data:image\/png;base64,/, '')
    return axios.post('/customer/document/sepa', {
      content: sign,
      iban
    })
  },

  startWebID () {
    return axios.get('/customer/start_webid')
  },

  startWebIDWithMode (mode) {
    return axios.post('/customer/start_webid_with_mode', {
      mode
    })
  },

  sendWebIdResponse (response = null) {
    return axios.post('/customer/webid_response', {
      response
    })
  },

  checkVertragsResponse (token) {
    return axios.get(`${process.env.api_base_url}/customer/anschreiben_mit_vertrag_plus_info?api_token=${token}`)
  },

  requestEngelVoelkers () {
    return axios.post('/customer/engelvoelkers')
  },

  rsvOptin (wantRsv) {
    return axios.post('/customer/rsv_optin',
      { want_rsv: wantRsv }
    )
  },

  finapiBankaccount () {
    return axios.get('/customer/finapi/bankaccount')
  }

})
